export const environment = {
  production: true,
  languageUrl: 'https://mapping.dev.estatepro.de/assets/i18n',
  baseUrl: 'https://main-estatecloud-migration.estate-dev.immocloud.io',
  cognitoServiceUrl: 'https://main-estate-dev.auth.eu-central-1.amazoncognito.com/',
  managementServiceUrl: 'https://management.dev.estatepro.de/',
  migrationApiUrl: 'https://main-estatecloud-migration.estate-dev.immocloud.io',
  mappingApiUrl: 'https://main-estatecloud-migration-mapping.estate-dev.immocloud.io',
  mappingUrl: 'https://mapping.dev.estatepro.de',
  authenticationConfigUrl: 'https://main-estatecloud-authentication.estate-dev.immocloud.io',
  supportLegitimateEmails: [
    'andrea.burghardt@immowelt.de',
    'frank.mueller@immowelt.de',
    'stefan.dietz@immowelt.de',
    'sdietz@immoweltag.de',
    'michael.fallmann@immowelt.de',
    'martin.tauber@immowelt.de',
    'vladislav.ackermann@immowelt.de',
    'artur.keller@immowelt.de',
    'aburghardt@immoweltag.de',
    'mueller@immoweltag.de',
    'mfallmann@hh.immoweltag.de',
    'm.tauber@immowelt.de',
    'vackermann@immoweltag.de',
    'akeller@immoweltag.de'
  ],
  stage: 'dev'
};
